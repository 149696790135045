:root {
  --font-primary: 'Lato', sans-serif;

  --white: #ffffff;
  --black: #333333;
  --black-span: #333;
  --blue: rgba(60, 129, 187, 1);
  --blue-50: rgba(60, 129, 187, 0.5);
  --green: #2bc457;
  --gray: rgb(210, 210, 215);
  --red: #de3a3a;
  --yellow: #ffbf5c;
  --yellow_alt: #efc285;
  --light-grey: #f7f8f8;
  --light-grey_alt: #d2d2d7;
  --lilac: #b055d5;
  --grey: rgb(112, 119, 135);
  --grey-10: rgba(112, 119, 135, 0.1);
  --grey-text: #6d7874;
  --grey-alt: #97aca9;
  --dark-grey: #434343;
  --dark-grey2: #3d3d3d;
  --dark-grey-font: #565656;

  --light-blue: #8bd0f0;
  --light-blue-text: #57b3ff;
  --light-blue-hover: #ebf3f5;
  --light-blue-10: rgba(60, 129, 187, 0.1);
  --notifications: #e25241;
  --gray-visited: rgba(210, 210, 215, 0.25);

  --background: linear-gradient(
    145deg,
    rgba(60, 129, 187, 1) 0%,
    rgba(21, 54, 92, 1) 100%
  );
  --header-bg: linear-gradient(272deg, #15365c, #3c81bb);

  // Colors (use it with [color] tag in HTML)
  .mat-icon.mat-grey {
    color: var(--light-grey_alt); // Example: use with color="grey" in HTML
  }
  .mat-icon.mat-warning {
    color: var(--yellow);
  }
}
