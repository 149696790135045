@import '../../styles/utilities/breackpoints.scss';

.mat-mdc-paginator {
  background: none;
}

@include devices(mobile, tablet) {
  .mat-mdc-paginator-range-label {
    margin: auto !important;
  }
}
