@import '../utilities/breackpoints.scss';
//Control de fletes y control de remitos
.mat-mdc-card {
  p {
    display: inline-block;
    font-size: 13px;
    margin: 0;
    padding: 0;
  }

  &.mat-sort p,
  &.mat-card-header p {
    font-size: 12px;
  }

  .typo-1 {
    color: var(--grey);

    button.link {
      font-weight: bold;
      color: var(--blue);
      text-decoration: none;
      border: none;
      background: transparent;
      cursor: pointer;
    }
  }

  .typo-2 {
    color: var(--dark-grey);
    font-weight: bold;
    font-size: 16px;
  }

  .typo-3 {
    color: var(--dark-grey);
    font-weight: bold;
  }
  .action-button {
    width: 100%;
    max-width: 260px;
    height: 30px;
    font-weight: 600;
    margin: auto;
  }

  .action-icon-button {
    padding: 0px !important;
    min-width: fit-content !important;
    width: 36px;
    border-radius: 8px;
    .mat-icon {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
  }
  .mat-mdc-outlined-button:not(:disabled) {
    color: var(--blue) !important;
  }
}
// Cards
.mat-mdc-card.mdc-card {
  margin-bottom: 10px;
  box-shadow: none;
  display: flex;
  flex-flow: column nowrap;

  .mat-mdc-card-content {
    display: flex;
    flex-flow: row nowrap;
    padding: 15px 20px;

    &.col {
      flex-flow: column nowrap;
    }

    .card-data-cell {
      display: flex;
      flex: 1;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      &:first-child {
        align-items: flex-start;
      }

      .cell-in-one-line {
        display: flex;
        align-items: center;
      }

      .mat-mdc-icon-button.mat-mdc-button-base {
        padding: 6px;
        width: 36px;
        height: 36px;
        &:not([disabled]) {
          color: var(--blue);
        }
      }

      .mat-icon {
        font-size: 20px;
        width: 20px;
        height: 20px;
      }

      .mat-mdc-chip.mat-mdc-standard-chip {
        font-weight: lighter;
        height: 22px;
        background-color: var(--grey-10);

        .mdc-evolution-chip__action--primary {
          padding-right: 10px;

          .mdc-evolution-chip__text-label {
            color: var(--grey-alt);
          }
        }

        .mdc-evolution-chip__graphic {
          padding: 0;

          .mat-icon {
            color: #035ee6; // TODO: Cuando se integren los estados el color va a ser dinámico
            font-size: 14px;
            width: 14px;
            height: 14px;
          }
        }
      }
    }

    .summary-cell {
      background-color: var(--grey-10);
      border-radius: 3px;
      padding: 5px 10px;
      margin: 8px -10px 0px;
    }
  }
}

@include devices(tablet, mobile) {
  .mat-mdc-card.mdc-card {
    .mat-mdc-card-content {
      flex-flow: column nowrap;

      &.col {
        flex-flow: column nowrap;
      }

      .card-data-cell {
        flex-flow: row nowrap;
        justify-content: space-between;
        width: 100%;
        min-height: 40px;
        border-bottom: solid 1px #f1f1f1;

        .typo-1.header {
          width: 30%;
          margin: 0 12px;
        }
        .card-data-cell-inner {
          display: flex;
          flex-direction: column;
        }

        &:first-child {
          align-items: flex-start;
        }

        .cell-in-one-line {
          display: flex;
          align-items: center;
          margin: auto;
        }

        .mat-mdc-icon-button.mat-mdc-button-base {
          padding: 6px;
          width: 36px;
          height: 36px;
          &:not([disabled]) {
            color: var(--blue);
          }
        }

        .mat-icon {
          font-size: 20px;
          width: 20px;
          height: 20px;
        }

        .mat-mdc-chip.mat-mdc-standard-chip {
          font-weight: lighter;
          height: 22px;
          background-color: var(--grey-10);

          .mdc-evolution-chip__action--primary {
            padding-right: 10px;

            .mdc-evolution-chip__text-label {
              color: var(--grey-alt);
            }
          }

          .mdc-evolution-chip__graphic {
            padding: 0;

            .mat-icon {
              color: #035ee6; // TODO: Cuando se integren los estados el color va a ser dinámico
              font-size: 14px;
              width: 14px;
              height: 14px;
            }
          }
        }
      }

      .summary-cell {
        background-color: var(--grey-10);
        border-radius: 3px;
        padding: 5px 10px;
        margin: 8px -10px 0px;
      }
    }
  }
  .cell-in-one-line {
    display: flex;
  }
}
