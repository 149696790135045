@import './../utilities/breackpoints.scss';

//Espaciados y otros
:root {
  .gap-5 {
    gap: 5px;
  }

  .gap-10 {
    gap: 10px;
  }

  .gap-80 {
    gap: 80px;
  }

  .m-0 {
    margin: 0 !important;
  }
  .mt-auto {
    margin-top: auto;
    align-self: flex-end;
  }

  .mx-auto {
    margin: 0 auto;
  }

  .my-auto {
    margin: auto 0;
  }

  .mt-5 {
    margin-top: 5px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mt-15 {
    margin-top: 15px;
  }
  .mt-20 {
    margin-top: 20px;
  }

  .mt-30 {
    margin-top: 30px;
  }

  .mb-0 {
    margin-bottom: 0px !important;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mb-15 {
    margin-bottom: 15px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }

  .ml-auto {
    margin-left: auto;
  }

  .ml-5 {
    margin-left: 5px;
  }

  .ml-sm-5 {
    margin-left: 5px;
  }
  .ml-sm-12 {
    margin-left: 12px;
  }
  .ml-12 {
    margin-left: 12px;
  }
  .ml-16 {
    margin-left: 16px;
  }
  .ml-24 {
    margin-left: 24px;
  }

  .ml-70 {
    margin-left: 70px;
  }

  .mr-auto {
    margin-right: auto;
  }

  .mr-0 {
    margin-right: 0;
  }

  .mr-5 {
    margin-right: 5px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .mr-18 {
    margin-right: 18px;
  }

  .mr-30 {
    margin-right: 30px;
  }

  .p-0 {
    padding: 0 !important;
  }
  .p-6 {
    padding: 6px !important;
  }

  //scroll
  .scroll {
    overflow: auto;
    white-space: nowrap;
  }
  .scroll::-webkit-scrollbar {
    width: 5px;
  }

  .scroll::-webkit-scrollbar-thumb {
    background-color: var(--blue);
    border-radius: 10px;
  }

  .scroll::-webkit-scrollbar-track {
    background: transparent;
  }

  @include devices(mobile, tablet) {
    .ml-sm-5 {
      margin-left: 0px;
    }
    .ml-sm-12 {
      margin-left: 0px;
    }

    .mx-sm-auto {
      margin: 0 auto !important;
    }
  }

  @include devices(tablet) {
    .mx-sm-auto {
      margin: 0 auto !important;
    }
  }
}
