// Button
:root {
  .pointer {
    cursor: pointer;
  }

  button {
    font-family: var(--font-primary);
    letter-spacing: normal;

    &.button-green {
      background-color: var(--green);
      color: var(--white);
    }

    &.button-red {
      background-color: var(--red);
      color: var(--white);
    }

    &.button-black {
      background-color: var(--dark-grey2);
      color: var(--white);
      border-radius: 8px;
      padding: 10px;

      &:hover {
        background-color: var(--dark-grey);
        cursor: pointer;
      }
    }

    &.button-blue {
      background-color: var(--blue);
      box-shadow: none;
      color: var(--white);
      font-size: 14px;
      font-weight: bold;

      &[disabled] {
        background-color: var(--blue-50);
      }
    }

    &.button-blue-flat {
      padding: 0 8px;
      background-color: var(--blue);
      color: var(--white);
      height: 39px;
      &[disabled] {
        background-color: var(--blue-50);
      }
    }

    &.button-transparent {
      margin: 9.8px 0 0;
      background-color: transparent;
      color: var(--blue);
      height: 48px;
      font-size: 14px;
      font-weight: bold;
      box-shadow: none;
    }

    &.button-login {
      border: solid 1px #dadce0;
      border-radius: 22px;
    }

    &.button-option {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 33px 20px 33px;
      width: 100%;
      height: 55px;
      box-sizing: border-box;

      &:hover {
        background-color: var(--light-blue-hover);
        cursor: pointer;
      }
    }

    &.button-light-blue {
      background-color: #eaf3f5;
      box-shadow: none;
      color: var(--black);
      width: 100%;
      border: 1px solid #e4eaea;
    }

    &.mat-mdc-icon-button {
      &.icon-button-w-background {
        background-color: #eaf3f5;
        margin: 0 5px 0 5px;
      }
    }

    &.squared-icon-button {
      height: 40px;
    }

    &.h-40 {
      height: 40px;
    }

    &.h-45 {
      height: 45px;
    }

    &.m-0 {
      margin: 0;
    }

    &.uppercase {
      text-transform: uppercase;
    }

    &.margin-top {
      margin: 30.3px 0 4.9px;
    }

    &.w-full {
      width: 100%;
    }

    .button-icon-blue {
      color: var(--blue);
    }
  }

  .mat-ripple-element {
    background-color: var(--light-blue-10);
  }
}
