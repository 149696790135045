.title-w-line {
  display: inline-block;
  width: 60%;
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
  height: 12px;
  box-sizing: border-box;
  padding: 10px 20px 10px 20px;
  margin: 10px 70px 0 70px;
}

.title-w-line-span {
  background-color: white;
  padding: 0 20px 0 20px;
}

.title-w-line-big {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
  height: 12px;
  box-sizing: border-box;
  padding: 5px 0 10px 0;
}
