/* You can add global styles to this file, and also import other style files */
@import "https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Round";
@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

//Components
@import "assets/styles/components/alerts.scss";
@import "assets/styles/components/buttons.scss";
@import "assets/styles/components/card.scss";
@import "assets/styles/components/forms.scss";
@import "assets/styles/components/grid-filters.scss";
@import "assets/styles/components/header.scss";
@import "assets/styles/components/links.scss";
@import "assets/styles/components/menu.scss";
@import "assets/styles/components/modals-dialogs.scss";
@import "assets/styles/components/navlist.scss";
@import "assets/styles/components/paginator.scss";
@import "assets/styles/components/sidebar.scss";
@import "assets/styles/components/stepper.scss";
@import "assets/styles/components/tabs.scss";
@import "assets/styles/components/title-w-line.scss";

//Layout
@import "assets/styles/layout/containers.scss";
@import "assets/styles/layout/grid.scss";

//Utilities
@import "assets/styles/utilities/spacing.scss";
@import "assets/styles/utilities/text.scss";

//Others
@import "assets/styles/_variables.scss";
@import "assets/styles/icons.scss";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.snackbar-dark {
  background-color: rgb(50, 50, 50);
  color: #ffffffb3;

  button {
    color: #ff4081;
  }
}

.pointer-event {
  cursor: pointer;
}

mat-label {
  color: gray !important;
}

.wrap-logo {
  align-self: center;
  margin: 0 auto 12px auto;
}

.logo {
  width: 165.4px;
  height: auto;
}

.center-flex {
  justify-content: center;
  align-items: center;
}
