//SideBar
.mat-drawer-container {
  height: 100%;
}

.sidebarContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
}

.form-un-sidebar {
  background-color: var(--white);
  margin: -30px auto -10px auto;
  width: 85%;
  border-radius: 4px;

  .mat-mdc-form-field-infix {
    margin-left: 6px;
    flex: auto;
    min-width: 0;
    width: auto;
    position: relative;
    box-sizing: border-box;
  }
}

.sidebar-options {
  .mdc-list-item.mdc-list-item--with-one-line .mdc-list-item__start {
    color: var(--dark-gray);
  }

  .mdc-list-item__primary-text {
    font-family: 'Lato', sans-serif;
  }

  &.mat-mdc-list-base {
    height: 100%;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    width: 300px;
  }
}
