// Tabs
.mat-mdc-tab-header {
  margin-bottom: 30px;
  border-bottom: 1px solid #d2d2d7;
  .mat-mdc-tab {
    padding-left: 0;
    padding-right: 0;
    margin-right: 10px;

    &:hover {
      .mdc-tab__ripple::before {
        opacity: 0;
      }
    }

    .mdc-tab__content {
      align-items: flex-start;
    }

    // Label
    .mdc-tab__text-label {
      padding: 7px 12px;
      border-radius: 15px;
      letter-spacing: normal;
      background-color: var(--white);
      color: #3c81bb;
    }
    // Indicador
    .mdc-tab-indicator__content--underline {
      border-color: #3c81bb;
    }

    &.mdc-tab--active:hover,
    &.mdc-tab--active:focus,
    &.mdc-tab-indicator--active {
      .mdc-tab__text-label {
        background-color: #3c81bb;
        color: var(--white);
      }
      .mdc-tab-indicator__content--underline {
        border-color: #3c81bb;
      }
    }
  }
}
