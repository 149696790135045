// Nav List
.mat-mdc-nav-list.list-dark {
  .mat-mdc-list-item {
    justify-content: space-between;
    background-color: var(--black);
    border-radius: 4px;
    margin: 0 0 10px 0;
    height: 56px;

    &:hover {
      background-color: var(--blue);
    }

    .mdc-list-item__content {
      align-self: center;
    }

    .mat-icon {
      color: var(--white);
    }

    .mdc-list-item__primary-text {
      color: var(--white);
      height: fit-content;
      align-items: center;
      font-size: 12px;
    }

    .mdc-list-item__secondary-text {
      color: var(--white);
      opacity: 0.75;
      font-size: 12px;
    }

    .mdc-list-item__end,
    .mdc-list-item__start {
      align-self: center;
      margin: 0 12px 0 22px;
    }
  }
}
.mat-mdc-nav-list.list-blue {
  padding: 8px;
  .mat-mdc-list-item {
    justify-content: space-between;
    background-color: var(--blue-50);
    border-radius: 4px;
    margin: 0 0 10px 0;
    height: 56px;

    &:hover {
      background-color: var(--blue);
    }

    .mdc-list-item__content {
      align-self: center;
    }

    .mat-icon {
      color: var(--white);
    }

    .mdc-list-item__primary-text {
      color: var(--white);
      height: fit-content;
      align-items: center;
      font-size: 10px;
    }

    .mdc-list-item__secondary-text {
      color: var(--white);
      opacity: 0.75;
      font-size: 12px;
    }

    .mdc-list-item__end,
    .mdc-list-item__start {
      align-self: center;
      margin: 0 12px 0 22px;
    }
  }
}

.mat-mdc-nav-list.user-options {
  display: flex;
  margin-top: 12px;
  flex-direction: row;
  align-items: center;
  border-radius: 25px;
  background-color: var(--black);
  padding: 15px 0 15px 0;
  height: 35px;
  box-sizing: border-box;

  &:hover {
    background-color: var(--blue);
  }

  .mdc-list-item__start {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #97aca9;
    color: var(--white);
    margin: 0 9.8px 0 5.7px;
    height: 24.5px;
    width: 24.5px;
    text-align: center;
  }

  .mat-icon {
    color: var(--white);
    margin-top: 5px;

    &.icon-17 {
      font-size: 17px;
    }
  }

  .mdc-list-item__primary-text {
    color: var(--white);
    display: flex;
  }

  .mdc-list-item__end {
    align-self: center;
    margin: 5px 11px 0 4px;
    color: var(--white);
  }
}
