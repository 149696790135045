@import '../utilities/breackpoints.scss';
//Stepper
.mat-stepper-horizontal {
  font-family: var(--font-primary);
  border-radius: 4px;
  width: 100%;
  padding: 25px 10px 0 10px;
  box-sizing: border-box;

  .mat-horizontal-stepper-header-container {
    display: none !important;
  }
}

@include devices(mobile) {
  .mat-stepper-horizontal {
    width: 100%;
  }
}
