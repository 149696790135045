.form-field {
  width: 100%;
  margin: 8.5px 0 0;
}

.select-wo-arrow {
  // Con esta clase eliminamos la flecha a los select
  .mat-mdc-select-arrow-wrapper {
    display: none;
  }
}

.mat-mdc-form-field-bottom-align::before {
  margin-left: -15px;
}
