@import '../../../assets/styles/utilities/breackpoints.scss';

.section-header {
  .common-filters,
  .custom-filters {
    .mat-mdc-form-field {
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }

      .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
        height: 40px;

        .mat-mdc-form-field-infix {
          padding-top: 8px;
          padding-bottom: 8px;
          min-height: 38px;
        }

        .mat-mdc-form-field-icon-prefix > .mat-icon,
        .mat-mdc-form-field-icon-suffix > .mat-icon {
          padding: 8px;
        }

        .mat-mdc-icon-button {
          --mdc-icon-button-state-layer-size: 40px;
          padding: 8px;
        }
      }

      .mat-mdc-form-field:not(:first-child) {
        margin-left: 12px;
        height: 40px;
      }
    }
  }

  .common-filters {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .custom-filters {
    display: flex;
    width: 100%;
    gap: 10px;

    .selected {
      .mat-mdc-text-field-wrapper.mdc-text-field--outlined,
      .mat-mdc-icon-button.mat-mdc-button-base {
        color: var(--white);
      }

      .mat-mdc-select-placeholder,
      .mat-mdc-select-trigger,
      .mat-mdc-form-field-infix {
        color: var(--white);
        z-index: 2;
      }

      .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: var(--blue);
          background-color: var(--blue);
          z-index: 0;
        }

        .mdc-text-field__input,
        .mat-date-range-input-separator {
          color: var(--white);
        }
      }

      &.mat-mdc-form-field-type-mat-date-range-input {
        min-width: 286px;
      }
    }
  }

  @include devices(tablet, mobile) {
    .custom-filters {
      flex-direction: column;
    }
  }
}
