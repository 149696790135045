:root {
  .alert {
    background-color: var(--white);
    border: 0.5px solid var(--grey);
    padding: 20px;
    margin: 0 0 30px;
    border-radius: 8px;
    position: relative;
    &:before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: var(--grey);
      border-radius: 8px 0 0 8px;
    }
    &:has(p) {
      padding-bottom: 8px;
      font-size: 13px;
      line-height: 150%;
      color: var(--black);
    }

    mat-icon {
      float: left;
      margin-right: 8px;
      color: var(--grey);
    }

    &.alert-info {
      background-color: #cfe0ee;
      border: 0.5px solid var(--blue);
      &:before {
        background-color: var(--blue);
      }
      mat-icon {
        color: var(--blue);
      }
    }
  }
}
