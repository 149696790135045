:root {
  .header-bg {
    background: var(--header-bg);
    color: var(--white);
    height: 56px;
  }

  .header-spacer {
    flex: 1 auto;
  }

  //Select
  header {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: transparent;
    }

    .mdc-text-field:focus {
      border: none;
    }

    .mat-mdc-select-value {
      color: var(--light-blue-text);
    }

    .mat-mdc-select-arrow svg {
      color: white;
    }

    .mdc-text-field--filled .mdc-line-ripple:after {
      border-bottom-color: transparent;
    }

    .mdc-line-ripple::after {
      border-bottom: none;
    }

    .mdc-line-ripple::before .md-line-ripple::after {
      display: none;
      border-bottom: none;
    }
  }

  .header-select-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  //Menu Notificaciones
  .header-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 20px 0 40px;
    box-sizing: border-box;
  }

  .notification-menu {
    &.mat-mdc-menu-panel.mat-mdc-menu-panel {
      min-width: 112px;
      max-width: 490px;
      padding-bottom: 35px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }

  .notification-read {
    &.mat-mdc-list-option {
      background-color: var(--gray-visited);
    }
  }

  .list-option {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .mdc-list-item--selected .mdc-list-item__primary-text {
    color: #434343;
  }

  .mdc-list-item__secondary-text {
    &.bold {
      font-weight: bold;
      color: #434343;
    }
  }

  .list-option-end {
    display: flex;
    align-items: center;
    margin-right: 15px;
  }
}
