@mixin devices($breakpoints...) {
  @each $breakpoint in $breakpoints {
    @if $breakpoint ==desktop {
      @media only screen and (min-width: 1280px) {
        @content;
      }
    }

    @if $breakpoint ==laptop {
      @media only screen and (min-width: 960px) and (max-width: 1279px) {
        @content;
      }
    }
    @if $breakpoint ==tablet {
      @media only screen and (min-width: 599px) and (max-width: 959px) {
        @content;
      }
    }

    @if $breakpoint ==mobile {
      @media only screen and (max-width: 598px) {
        @content;
      }
    }
  }
}
