@import '../utilities/breackpoints.scss';
.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font-family: var(--font-primary);
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
  color: var(--black);
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography,
span {
  font-family: var(--font-primary);
}

.paragraphs {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
  width: 320px;
  text-justify: auto;
}

.mat-typography h1 {
  margin: 0 0 15px;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.text-center {
  text-align: center;
}

.text-uppercase {
  text-transform: uppercase;
}

//Size
.size-12,
p.size-12 {
  font-size: 12px;
}

.size-13,
p.size-13 {
  font-size: 13px !important;
}

.size-15,
p.size-15 {
  font-size: 15px;
}

.size-16,
p.size-16 {
  font-size: 16px;
}

//Weight
.bold {
  font-weight: bold;
}
.weight-400 {
  font-weight: 400 !important;
}

.weight-500 {
  font-weight: 500 !important;
}

.weight-600 {
  font-weight: 600;
}

.weight-normal {
  font-weight: normal;
}

//Color
.black {
  color: var(--black);
}

.gray-title {
  color: #495752;
}

.grey-alt {
  color: #97aca9;
}
@include devices(mobile, tablet) {
  .paragraphs {
    width: 300px;
  }
  .mat-typography h1 {
    width: 80%;
  }
  .mat-typography .h1-sm {
    width: 100%;
    text-align: center;
  }
  .w-full-sm {
    width: 100%;
  }
}
